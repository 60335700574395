
import axios from '../../utils/AxiosWrapper'
import { formatCampaignOverviewData } from './helpers'
import axiosMethodRequest from '../../config/service'
import { GetSessionValue, SetSessionValue } from '../../utils/sessionStorage'

export const setCampaignOverviewSearchAction = search => {
  return {
    type: `SET_CAMPAIGNOVERVIEW_SEARCH`,
    payload: { search }
  }
}

export const setCampaignOverviewSearchOptionsAction = options => {
  return {
    type: `SET_CAMPAIGNOVERVIEW_SEARCH_OPTIONS`,
    payload: options
  }
}


const setCampaignOverviewData = data => {
  return {
    type: `SET_CAMPAIGNOVERVIEW_DATA`,
    payload: data
  }
}
const setCampaignOverviewLoadingState = loadingState => {
  return {
    type: `SET_CAMPAIGNOVERVIEW_DATA_LOADINGSTATE`,
    payload: loadingState
  }
}

const setCampaignOverviewRawData = data => {
  return {
    type: `SET_RAW_CAMPAIGNOVERVIEW_RAW_DATA`,
    payload: data
  }
}

export const clearCampaignOverviewAction = () => {
  return dispatch => {
    dispatch(setCampaignOverviewData(null))
  }
}
// export const getCampaignOverviewAction = (params) => {

//   return dispatch => {
//     dispatch(setCampaignOverviewLoadingState(true));
//     let query = "";
//     // params.brandName = "";
//     if (params.campaign_id) {
//       if(params.brandId) {
//         query = `&brandId=${params.brandId}&brandName=${encodeURIComponent(params.brandName)}`;
//       }
//       if(params.data_source) {
//         query += `&data_source=${params.data_source}`;
//       }
//       if(params.tranCustomerType) {
//         query += `&tranCustomerType=${params.tranCustomerType}`;
//       }
//       query += `&is_invoice_data=${params.is_invoice_data}`;
//       if(params.selectedInvoiceNumber) {
//         query += `&selectedInvoiceNumber=${params.selectedInvoiceNumber}`;
//       }
//       if(params.selectedOfferType) {
//         query += `&selectedOfferType=${params.selectedOfferType}`;
//       }

// //    Things need to change   :   



//       return axiosMethodRequest('GET', `dashboard?campaign_id=${params.campaign_id}${query}`).then(async (response) => {
//         if(response.data && response.data.invoiceList && response.data.invoiceList.length > 0) {
//           response.data.invoiceList = await verifyInvoices(response.data.invoiceList);
//         }
//         dispatch(setCampaignOverviewLoadingState(false));
//         response = updateDashboardChartData(response);
//         dispatch(setCampaignOverviewData(response.data))
//         console.log('response', response.data)
//       })
//     } else {
//       if(params.brandId) {
//         query = `?brandId=${params.brandId}&brandName=${encodeURIComponent(params.brandName)}`;
//       }
//       if(params.groupId) {
//         query = query + `&groupId=${params.groupId}`
//       }
//       if(params.data_source) {
//         query += `&data_source=${params.data_source}`;
//       }
//       if(params.tranCustomerType) {
//         query += `&tranCustomerType=${params.tranCustomerType}`;
//       }
//       query = query ? query + `&is_invoice_data=${params.is_invoice_data}` : `?is_invoice_data=${params.is_invoice_data}`;
//       if(params.selectedInvoiceNumber) {
//         query += `&selectedInvoiceNumber=${params.selectedInvoiceNumber}`;
//       }
//       if(params.selectedOfferType) {
//         query += `&selectedOfferType=${params.selectedOfferType}`;
//       }
//       return axiosMethodRequest('GET', 'dashboard' + query).then(async (response) => {
//         if(response.data && response.data.invoiceList && response.data.invoiceList.length > 0) {
//           response.data.invoiceList = await verifyInvoices(response.data.invoiceList);
//         }
//         dispatch(setCampaignOverviewLoadingState(false));
//         response = updateDashboardChartData(response);
//         dispatch(setCampaignOverviewData(response.data))
//         console.log('response', response.data)
//       })
//     }

//     // return axios
//     // .get('/campaignoverview/', {params})
//     // .then(res => {
//     //   let data = formatCampaignOverviewData(res.data)
//     //   dispatch(setCampaignOverviewData(data))
//     //   setCampaignOverviewLoadingState(false)
//     // })
//     // .catch(err => {
//     //   setCampaignOverviewLoadingState(false)
//     // })
//   }
// }

export const getCampaignOverviewAction = (params) => {
  return dispatch => {
    dispatch(setCampaignOverviewLoadingState(true));
    let userDetails = GetSessionValue('user')
    let conditions = {};

    if (params.campaign_id) {
      if (userDetails) {
        switch (userDetails.role) {
          case "brandAdmin":
            conditions.brand_id = userDetails.brandId
            conditions.brand_name = userDetails.brandName;
            break;
          case "brandUser":
            conditions.brand_id = userDetails.brandId
            conditions.brand_name = userDetails.brandName;
          case "companyAdmin":
            conditions.brand_id = userDetails.brandId
            conditions.brand_name = userDetails.brandName;
          case "groupAdmin":
            conditions.group_id = userDetails.brandId
            conditions.group_name = userDetails.brandName;
          case "groupAdmin":
            conditions.group_id = userDetails.brandId
            conditions.group_name = userDetails.brandName;
          default:
            break;
        }
      }
      if (params.brandId&&params.brandId!=="All") {
        conditions.brand_id = params.brandId;
        conditions.brand_name = params.brandName;
      }
      if (params.data_source) {
        conditions.data_source = params.data_source;
      }
      if (params.tranCustomerType) {
        conditions.customer_type_wrt_account = params.tranCustomerType;
      }
      conditions.is_invoice_data = params.is_invoice_data;
      if (params.selectedInvoiceNumber) {
        conditions.xero_invoice_number = params.selectedInvoiceNumber;
      }
      if (params.selectedOfferType) {
        conditions.selectedOfferType = params.selectedOfferType;
      }
      if (params.campaign_id) {
        conditions.campaign_id = params.campaign_id;
      }

      let request = {
        conditions_body: {
          conditions: conditions,
          conditions_reconciliation: {}
        },
        field: []
      };

      let endpoint = "/client"
      return axiosMethodRequest('POST', endpoint, request).then(async (response) => {
        if (response.data && response.data.invoiceList && response.data.invoiceList.length > 0) {
          response.data.invoiceList = await verifyInvoices(response.data.invoiceList);
        }
        dispatch(setCampaignOverviewLoadingState(false));
        response = updateDashboardChartData(response);
        dispatch(setCampaignOverviewData(response.data));
        console.log('response', response.data);
      });
    } else {
      console.log("User Details     :    " + JSON.stringify(userDetails))

      let conditions = {
        "is_invoice_data": false,
      };
      if (userDetails) {
        switch (userDetails.role) {
          case "brandAdmin":
            conditions.brand_id = userDetails.brandId
            conditions.brand_name = userDetails.brandName;
            break;
          case "brandUser":
            conditions.brand_id = userDetails.brandId
            conditions.brand_name = userDetails.brandName;
          case "companyAdmin":
            conditions.brand_id = userDetails.brandId
            conditions.brand_name = userDetails.brandName;
          case "groupAdmin":
            conditions.group_id = userDetails.brandId
            conditions.group_name = userDetails.brandName;
          case "groupAdmin":
            conditions.group_id = userDetails.brandId
            conditions.group_name = userDetails.brandName;
          default:
            break;
        }
      }
      if (params.brandId&&params.brandId!=="All") {
        conditions.brand_id = params.brandId;
        conditions.brand_name = params.brandName;
      }
      if (params.groupId) {
        conditions.group_id = params.groupId;
      }
      if (params.data_source) {
        conditions.data_source = params.data_source;
      }
      if (params.tranCustomerType) {
        conditions.customer_type_wrt_account = params.tranCustomerType;
      }
      if (params.selectedInvoiceNumber) {
        conditions.xero_invoice_number = params.selectedInvoiceNumber;
      }

      if (params.selectedOfferType) {
        conditions.selectedOfferType = params.selectedOfferType;
      }
      let request = {
        conditions_body: {
          conditions: conditions,
          conditions_reconciliation: {}
        },
        field: []
      };
      console.log("Axios Request    :    " + JSON.stringify(request))
      let endpoint = "/client"
      return axiosMethodRequest('POST', endpoint, request).then(async (response) => {
        if (response.data && response.data.invoiceList && response.data.invoiceList.length > 0) {
          response.data.invoiceList = await verifyInvoices(response.data.invoiceList);
        }
        dispatch(setCampaignOverviewLoadingState(false));
        response = updateDashboardChartData(response);
        dispatch(setCampaignOverviewData(response.data));
        console.log('response', response.data);
      });
    }
  };
};


export const verifyInvoices = (invoiceList) => {

  const promise = new Promise(async (resolve, reject) => {
    let invoiceNumber = [];
    invoiceList.forEach(element => {
      invoiceNumber.push(element.xero_invoiceNumber)
    });
    invoiceNumber = invoiceNumber.join(",");
    const url = `transaction/verifyInvoices?InvoiceNumbers=` + invoiceNumber
    return axiosMethodRequest('GET', url).then((response) => {
      let filterInvoiceList = [];
      if (response.data && response.data.invoiceList) {
        response.data.invoiceList.forEach(element => {
          let filter = invoiceList.filter(a => a.xero_invoiceNumber == element);
          if (filter && filter.length > 0) {
            filterInvoiceList.push(filter[0]);
          }
        });
        filterInvoiceList = invoiceList.length > 1 ? [{ xero_invoiceNumber: "All", _id: "All" }].concat(filterInvoiceList) : filterInvoiceList;
      }
      resolve(filterInvoiceList);
    })
  });
  return promise;
}

const updateDashboardChartData = (response) => {
  if (response.data && response.data.performance) {
    if (response.data.performance.conversions && response.data.performance.conversions.length > 0) {
      response.data.performance.conversions = response.data.performance.conversions.sort((a, b) => {
        a.newLabel = a.label;
        b.newLabel = b.label;
        a.newLabel = addAdditionalValue(a.newLabel);
        b.newLabel = addAdditionalValue(b.newLabel);
        return parseInt(a.newLabel) - parseInt(b.newLabel)
      });
    }
    if (response.data.performance.revenue && response.data.performance.revenue.length > 0) {
      response.data.performance.revenue = response.data.performance.revenue.sort((a, b) => {
        a.newLabel = a.label;
        b.newLabel = b.label;
        a.newLabel = addAdditionalValue(a.newLabel);
        b.newLabel = addAdditionalValue(b.newLabel);
        return parseInt(a.newLabel) - parseInt(b.newLabel)
      });
    }
  }
  return response;
}

const addAdditionalValue = (value) => {
  if (value.indexOf("-") != -1 && value.split("-")[1].length == 1) {
    value = value.split("-")[0] + "0" + value.split("-")[1];
  }
  return value.replaceAll("-", "");
}

export const getInvoiceDataAction = (brandName, month, year, transaction) => {
  return dispatch => {
    let url = '/dashboard/getInvoice/' + brandName + '?type=detailed';
    if (month && year) {
      url = `/dashboard/getInvoice/${brandName}?type=detailed&month=${month}&year=${year}`
    }
    if (transaction) {
      url = `/dashboard/getInvoice/${brandName}?type=detailed&transaction=${true}`
    }
    return axiosMethodRequest('GET', url).then((response) => {
      dispatch({
        type: `SET_INVOICE_DATA`,
        payload: response.data
      })
    })
  }
}

export const clearInvoiceDataAction = () => {
  return dispatch => {
    dispatch({
      type: `SET_INVOICE_DATA`,
      payload: {}
    })
  }
}


export const generateInvoiceDataAction = (brandName, type) => {
  return dispatch => {
    let url = ""
    if (type) {
      url = `/dashboard/getInvoice/${brandName}?type=detailed`
    } else {
      url = `/dashboard/getInvoice/${brandName}`
    }
    return axiosMethodRequest('GET', url).then((response) => {
      dispatch({
        type: `SET_INVOICE_DATA`,
        payload: response.data
      })
    })
  }
}



export const getCampaignOverviewRawAction = params => {
  return dispatch => {
    // return axiosMethodRequest
    return axios
      .get('/campaignoverview/raw', { params })
      .then(res => {
        let data = res.data
        if (!data) {
          data = {}
        }
        dispatch(setCampaignOverviewRawData(data))
      })
      .catch(err => {
      })
  }
}

export const getCampaignOverviewSearchOptionsAction = (params, callback) => {
  return dispatch => {
    setCampaignOverviewLoadingState(true)
    return axiosMethodRequest('GET', 'campaigns').then(res => {
      let data = res.data.campaigns;
      console.log(data)
      setCampaignOverviewLoadingState(false)
      dispatch(setCampaignOverviewSearchAction(data))
      // if (callback)
      //   callback(data)
    })
      .catch(err => {
        setCampaignOverviewLoadingState(false)
        if (callback)
          callback(null)
      })
    // .get('/campaigns/search', { params })
    // .get('/campaigns')

  }
}

export const updateCampaignOverviewAction = (data, callback) => {
  return dispatch => {
    return axios
      .post('/campaignoverview/createfromdata', data)
      .then(res => {
        if (callback)
          callback(true)
      })
      .catch(err => {
        if (callback)
          callback(null)
      })
  }
}

export const uploadDocsAction = ({ transactions, impressions, start_date, end_date }, callback) => {
  return dispatch => {
    let formData = new FormData()
    formData.append('transactions', transactions)
    formData.append('impressions', impressions)
    formData.append('start_date', start_date)
    formData.append('end_date', end_date)

    return axios
      .post('/campaignoverview/upload', formData)
      .then(res => {
        if (callback)
          callback()
      })
      .catch(err => {
        if (callback)
          callback(err)
      })
  }
}

export const triggerCOCreateAction = (data, callback) => {
  return dispatch => {
    setCampaignOverviewLoadingState(true)
    return axios
      .post('/campaignoverview/create', data)
      .then(res => {
        let data = res.data.campaigns
        setCampaignOverviewLoadingState(false)
        if (callback)
          callback(data)
      })
      .catch(err => {
        setCampaignOverviewLoadingState(false)
        if (callback)
          callback(err)
      })
  }
}

export const setCampaignOverviewToLocalAction = (data, id) => {
  let key = `campaignoverview_raw_${id}`
  SetSessionValue(key, data);
  return {
    type: ''
  }
}

export const getCampaignOverviewFromLocalAction = (id) => {
  let key = `campaignoverview_raw_${id}`
  let data = GetSessionValue(key);
  if (data)
    // data = JSON.parse(data)
    data = formatCampaignOverviewData(data)
  return {
    type: 'SET_CAMPAIGNOVERVIEW_FROM_LOCAL',
    payload: data
  }
}




