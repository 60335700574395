import React, { Component } from "react";
import CampaignOverview from '../../components/CampaignOverview'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import * as Datetime from 'react-datetime'
import { Collapse } from 'react-bootstrap';
import { Modal, ModalHeader, ModalBody, Row } from 'reactstrap';
import {
  // getCampaignOverviewSearchOptionsAction,
  clearCampaignOverviewAction,
  // setCampaignOverviewSearchAction,
  getInvoiceDataAction,
  clearInvoiceDataAction,
  generateInvoiceDataAction,
  getBrandGroupWiseAction,
  getGroupAction,
  getDistributorsAction
} from '../../redux/actions'
import AsyncSelect from 'react-select/lib/Async';
import Select from 'react-select';

import axiosMethodRequest, { getDetailedInfo } from './../../config/service';
import Icon from '@material-ui/core/Icon'
import SessionExpiryModal from './../../components/SessionExpiryModal';
import { Redirect } from "react-router";
import { GetSessionValue } from "../../utils/sessionStorage";
import config from '../../config/config'
import { TransactionList } from "./transactionList"
import {
  success as successNotificationAction,
  error as errorNotificationAction
} from 'react-notification-system-redux';
import { getXeroInvoiceAction } from '../../redux/actions/index';
import { notificationOpts } from '../../config';
import Toastify from 'toastify-js'
import "toastify-js/src/toastify.css"
import { ToastMessage } from "./Toastify";

// import { ToastMessage } from "./Toastify";

class CampaignOverviewLayout extends Component {
  // handleInputChange = (value) => {
  //   let { getCampaignOverview } = this.props;
  //   getCampaignOverview({id : value.id})
  // }
  state = {
    showFilter: false,
    start_date: null,
    end_date: null,
    campaigns: [],
    sessionExpired: false,
    dashboardRoute: null,
    duplicateData: {
      "demographic": {
        "age": [],
        "branches": [],
        "nationality": [],
        "gender": [
          {
            "label": "F",
            "value": 464756700
          },
          {
            "label": "M",
            "value": 267356698
          }
        ]
      },
      "performance": {
        "reach": [
          {
            "label": "2021-02",
            "value": 2344
          },
          {
            "label": "2021-01",
            "value": 1234
          },
          {
            "label": "2019-51",
            "value": 43433
          },
          {
            "label": "2019-50",
            "value": 43433
          }
        ],
        "conversions": [
          {
            "label": "2021-02",
            "value": 2344
          },
          {
            "label": "2021-01",
            "value": 1234
          },
          {
            "label": "2019-51",
            "value": 43433
          },
          {
            "label": "2019-50",
            "value": 43433
          }
        ],
        "revenue": [
          {
            "label": "2021-02",
            "value": 5454
          },
          {
            "label": "2021-01",
            "value": 43555
          },
          {
            "label": "2019-51",
            "value": 5446
          },
          {
            "label": "2019-50",
            "value": 5446
          }
        ]
      },
      "statistics": [
        {
          "name": "reach",
          "value": 9775
        },
        {
          "name": "conversions",
          "value": 9775
        },
        {
          "name": "revenue",
          "value": 11324
        },
        {
          "name": "total_cost",
          "value": 1359
        },
        {
          "name": "cpa",
          "value": 0
        }
      ],
      "count": 260,
      "start_date": "2020-07-01",
      "end_date": "2021-02-28"
    },
    isloader: false,
    selectValue: {
      isAll: true,
      value: ""
    },
    isShowTransModel: false,
    defaultValue: { label: "All", value: null },
    selectedBrand: { label: "", value: null },
    userDetails: GetSessionValue('user'),
    groupList: [],
    groupDetailList: [],
    selectedGroup: { label: "All", value: "All" },
    tempAllBrands: [],
    urlquery_campaign_id: "",
    selectedPublisher: { label: "All", value: "All" },
    publisherList: [],
    allSelectedFilter: {},
    ingestionLogDate: {},
    ingestionLogList: [],
    isInvoiceData: false,
    tranCustomerTypes: [
      { label: 'All', value: 'All' },
      { label: "New Customers", value: "NEW USER" },
      { label: "Existing Customers", value: "REPEAT USER" }
    ],
    selectedTranCustomerType: { label: "All", value: "All" },
    selectedOfferType: { label: "All", value: "All" },
    selectedInvoiceNumber: ""
  }
  handleDateChange = (name, date) => {
    this.setState({ [`${name}`]: date })
  }
  componentDidMount = async () => {
    console.log("Email Notifications    :    " + JSON.stringify(this.props))
    // let { getCampaignOverviewSearchOptions, setCampaignOverviewOptions } = this.props;
    // await getCampaignOverviewSearchOptions();
    // console.log(await setCampaignOverviewOptions())
    this.getBrandsAndCampaignList();
  }

  getBrandsAndCampaignList = async () => {
    if (this.state.userDetails && this.state.userDetails.isClientAdmin) {
      axiosMethodRequest('GET', 'brands/getBrandList').then((response) => {
        if (response && response.data && response.data.respCode) {
          this.setState({ brandsList: response.data.brands, tempAllBrands: response.data.brands });
          if (response.data.brands.length > 0) {
            this.setState({ selectedBrand: { value: response.data.brands[0].value, label: response.data.brands[0].label } });
            this.getUploadedDate("brand", response.data.brands[0].value);
          }
          this.getCampaignList();
        } else if (response && response.data && response.data.errorMessage == "Session expired, please login again.") {
          this.setState({ sessionExpired: true });
          console.log('respos ', response);
        }
      });
    } else if (this.state.userDetails.role === "groupAdmin" || this.state.userDetails.role == 'groupUser') {
      let { getBrandGroupWise } = this.props;
      await getBrandGroupWise(this.state.userDetails.brandId, async (res) => {
        let brandsList = [{ label: "All", value: "All" }];
        brandsList = brandsList.concat(res);
        this.setState({ brandsList: brandsList, selectedBrand: { label: "All", value: "All" } });
        this.getUploadedDate("", "");
        // if (res.length > 0) {
        //   this.setState({ selectedBrand: { value: res[0].value, label: res[0].label } });
        // }
        this.getCampaignList();
      });
    } else {
      this.getUploadedDate("", "");
      this.getCampaignList();
    }


    if (this.state.userDetails && this.state.userDetails.isClientAdmin) {
      let { getGroups } = this.props;
      await getGroups(async (res) => {
        let list = [{ label: "All", value: "All" }];
        res.forEach(element => {
          list.push({ _id: element._id, label: element.group_name, value: element.groupId });
        });
        this.setState({ groupList: list, selectedGroup: { label: "All", value: "All" }, groupDetailList: res });
      });
    }


    let { getDistributors } = this.props;
    await getDistributors(async (res) => {
      let list = [{ label: "All", value: "All" }];
      res.forEach(element => {
        if (!element.parentPublisherId) {
          list.push({ label: element.distributors_display_name, value: element.distributors_name });
        }
      });
      this.setState({ publisherList: list, selectedPublisher: { label: "All", value: "All" } });
    });

  }

  getCampaignList = () => {

    let campaigns = [{ label: 'All', value: null }];
    let urlParams = ``;
    if (this.state.userDetails && this.state.userDetails.isClientAdmin) {
      urlParams = `&isClientAdmin=${true}&brandId=${this.state.selectedBrand.value}&brandName=${encodeURIComponent(this.state.selectedBrand.label)}`;
      if (this.state.selectedGroup.value !== "All" && this.state.selectedBrand.value === "All") {
        urlParams = urlParams + `&groupId=${this.state.selectedGroup.value}`;
      }
    } else if (this.state.userDetails && this.state.selectedBrand.value == "All" && (this.state.userDetails.role === "groupAdmin" || this.state.userDetails.role === "groupUser")) {
      urlParams = `&isGroupAdmin=${true}&brandId=${this.state.selectedBrand.value}&brandName=${encodeURIComponent(this.state.selectedBrand.label)}`;
    } else if (this.state.userDetails && this.state.selectedBrand.value && (this.state.userDetails.role === "groupAdmin" || this.state.userDetails.role === "groupUser")) {
      urlParams = `&isGroupAdmin=${true}&brandId=${this.state.selectedBrand.value}&brandName=${encodeURIComponent(this.state.selectedBrand.label)}&from=clientDashboard`;
    }
    urlParams = urlParams + `&from=dashboard`;
    axiosMethodRequest('GET', 'campaigns?campaignType=All' + urlParams).then((response) => {
      if (response && response.data && response.data.respCode) {
        let filterSelect = {
          brandId: this.state.selectedBrand.value,
          brandName: this.state.selectedBrand.label
        };
        if (this.state.selectedGroup.value !== "All" && this.state.selectedBrand.value === "All") {
          filterSelect.groupId = this.state.selectedGroup.value;
        }

        if (this.props.selectedCampaign_id && !this.state.urlquery_campaign_id) {
          filterSelect = {
            campaign_id: this.props.selectedCampaign_id
          };
          this.setState({ urlquery_campaign_id: this.props.selectedCampaign_id });
        }

        if (this.state.selectedPublisher.value != "All") {
          filterSelect.data_source = this.state.selectedPublisher.value;
        }

        console.log("this.state.selectedBrand", this.state.selectedBrand);
        console.log("this.state.userDetails.role", this.state.userDetails.role);
        if ((this.state.selectedBrand.value === "All" || !this.state.selectedBrand.value) && this.state.userDetails.role == "companyAdmin" &&
          !this.state.userDetails.isClientAdmin) {
          filterSelect.brandId = this.state.userDetails.brandId;
          filterSelect.brandName = this.state.userDetails.brandName;
          this.setState({ selectedBrand: { label: filterSelect.brandName, value: filterSelect.brandId } });
        }

        filterSelect.is_invoice_data = this.state.isInvoiceData;
        this.setState({ allSelectedFilter: filterSelect });
        this.props.handleFilterChange(filterSelect);
        let data = response.data.campaigns
        data.forEach((item, index) => {
          campaigns.push({
            label: item.name,
            value: item.campaign_id,
            data: item
          })
        })
        if (this.props.selectedCampaign_id) {
          let filterc = campaigns.filter(res => res.value == this.props.selectedCampaign_id)
          this.setState({
            defaultValue: {
              label: filterc[0].label,
              value: filterc[0].value,
              data: filterc[0].data
            }
          });
        }
        this.setState({ campaigns: campaigns })
      } else if (response && response.data && response.data.errorMessage == "Session expired, please login again.") {
        this.setState({ sessionExpired: true })
        console.log('respos ', response)
      }
    })
  }

  componentDidUpdate(nextProps) {
    let { invoiceState } = this.props;
    if (invoiceState.fileName && invoiceState.pdfType) {
      this.setState({ isloader: false })
      const link = document.createElement('a');
      link.href = `${config.imgUrl}invoices/detailed/${invoiceState.fileName}`;
      link.setAttribute(
        "download",
        invoiceState.fileName
      );
      link.setAttribute(
        "target",
        "_blank"
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.props.clearInvoiceDataAction()
    }
  }

  getBrand = (value) => {
    let filterCriteria = {
      'criteria': [
        {
          'key': 'name',
          'value': value,
          'type': 'regexOr'
        }
      ]
    }
    let urlParams = ""
    if (value && value.length >= 3) {
      urlParams = `campaigns?campaignType=All&filter=${JSON.stringify(filterCriteria)}`
    } else {
      urlParams = 'campaigns?campaignType=All'
    }

    if (this.state.userDetails && this.state.userDetails.isClientAdmin) {
      urlParams = `&isClientAdmin=${true}`;
    }

    let campaigns = [{ label: 'All', value: null }]
    axiosMethodRequest('GET', urlParams).then((response) => {
      if (response && response.data && response.data.respCode) {
        let data = response.data.campaigns
        data.forEach((item, index) => {
          campaigns.push({
            label: item.name,
            value: item.campaign_id,
            data: item
          })
        })
        this.setState({ campaigns: campaigns })
      } else if (response && response.data && response.data.errorMessage == "Session expired, please login again.") {
        this.setState({ sessionExpired: true })
      }
    })
  }

  selectOnGroupChange = async (e) => {

    this.setState({
      selectedGroup: e,
      selectedBrand: { label: "All", value: "All" }, defaultValue: { label: "All", value: null }
    });

    if (e.value === "All") {
      this.setState({ selectedBrand: { value: this.state.tempAllBrands[0].value, label: this.state.tempAllBrands[0].label } });
      this.setState({ brandsList: this.state.tempAllBrands });
    } else {
      console.log("this.state.groupDetailList", this.state.groupDetailList)
      let g_Detail = this.state.groupDetailList.filter(a => a.groupId == e.value);
      if (g_Detail && g_Detail[0] && g_Detail[0].group_brand_ids) {
        let brandsList = [{ label: "All", value: "All" }];
        this.setState({ brandsList: brandsList.concat(g_Detail[0].group_brand_ids) });
      }
    }

    let filterSelect = {
      // campaign_id: this.state.defaultValue.value,
      campaign_id: null,
      brandId: "All",
      brandName: "All",
      groupId: e.value
    };

    if (this.state.selectedPublisher.value != "All") {
      filterSelect.data_source = this.state.selectedPublisher.value;
    }
    filterSelect.is_invoice_data = this.state.isInvoiceData;
    this.setState({ allSelectedFilter: filterSelect });
    this.props.handleFilterChange(filterSelect);
    this.getUploadedDate("group", e.value);
    setTimeout(() => {
      this.getCampaignList();
    }, 1000);
  }

  selectOnBrandChange = async (e) => {
    this.setState({ selectedBrand: e, defaultValue: { label: "All", value: null } });
    let filterSelect = {
      // campaign_id: this.state.defaultValue.value,
      campaign_id: null,
      brandId: e.value,
      brandName: e.label
    };
    if (this.state.selectedPublisher.value != "All") {
      filterSelect.data_source = this.state.selectedPublisher.value;
    }
    filterSelect.is_invoice_data = this.state.isInvoiceData;
    this.setState({ allSelectedFilter: filterSelect });
    let { campaignOverview } = this.props;
    if (campaignOverview && campaignOverview.is_two_customer && this.state.selectedTranCustomerType.value != "All"
      && (this.state.selectedGroup.value !== "All" || this.state.selectedBrand.value != "All") && this.state.selectedPublisher.value == "ATR") {
      filterSelect.tranCustomerType = this.state.selectedTranCustomerType.value;
    } else {
      delete filterSelect.tranCustomerType;
    }
    this.props.handleFilterChange(filterSelect);
    this.getUploadedDate("brand", e.value);
    setTimeout(() => {
      this.getCampaignList();
    }, 1000);
  }

  selectOnChange = async (e) => {
    if (e) {
      let filterSelect = {
        campaign_id: e.value,
      }

      this.setState({ dashboardRoute: e.value });
      this.setState({ defaultValue: e });
      if (e.label == "All") {
        this.setState({ selectValue: { ...this.state.selectValue, value: e.value, isAll: true } })
      } else {
        this.setState({ selectValue: { ...this.state.selectValue, value: e.value, isAll: false } })
      }
      if (this.state.userDetails && this.state.userDetails.isClientAdmin) {
        filterSelect.brandId = this.state.selectedBrand.value;
        filterSelect.brandName = this.state.selectedBrand.label;
      } else if (this.state.selectedBrand.value && this.state.userDetails && (this.state.userDetails.role === "groupAdmin" || this.state.userDetails.role === "groupUser")) {
        filterSelect.brandId = this.state.selectedBrand.value;
        filterSelect.brandName = this.state.selectedBrand.label;
      }

      if (filterSelect.campaign_id && filterSelect.brandId == "All") {
        delete filterSelect.brandId;
        delete filterSelect.brandName;
      }

      console.log("filterSelect", filterSelect);

      if (this.state.selectedPublisher.value != "All") {
        filterSelect.data_source = this.state.selectedPublisher.value;
      }
      if (this.state.selectedBrand.value == "All" && e.label == "All" &&
        this.state.selectedGroup.value != "All") {
        filterSelect.groupId = this.state.selectedGroup.value;
      }
      filterSelect.is_invoice_data = this.state.isInvoiceData;
      this.setState({ allSelectedFilter: filterSelect });
      let { campaignOverview } = this.props;
      if (campaignOverview && campaignOverview.is_two_customer && this.state.selectedTranCustomerType.value != "All"
        && (this.state.selectedGroup.value !== "All" || this.state.selectedBrand.value != "All") && this.state.selectedPublisher.value == "ATR") {
        filterSelect.tranCustomerType = this.state.selectedTranCustomerType.value;
      } else {
        delete filterSelect.tranCustomerType;
      }
      this.props.handleFilterChange(filterSelect);
      // this.getUploadedDate("campaign", e.value);
    }
  }

  selectOnPublisherChange = async (e) => {
    this.setState({ selectedPublisher: e });
    let filterSelect = this.state.allSelectedFilter;
    if (e.value == "All") {
      delete filterSelect.data_source;
    } else {
      filterSelect.data_source = e.value;
    }
    filterSelect.is_invoice_data = this.state.isInvoiceData;
    let { campaignOverview } = this.props;
    if (campaignOverview && campaignOverview.is_two_customer && this.state.selectedTranCustomerType.value != "All"
      && (this.state.selectedGroup.value !== "All" || this.state.selectedBrand.value != "All") && this.state.selectedPublisher.value == "ATR") {
      filterSelect.tranCustomerType = this.state.selectedTranCustomerType.value;
    } else {
      delete filterSelect.tranCustomerType;
    }
    this.props.handleFilterChange(filterSelect);
    this.getUploadedDate("publisher", e.value);
  }

  onIsInvoiceChangeData = async () => {
    let filterSelect = this.state.allSelectedFilter;
    filterSelect.is_invoice_data = !this.state.isInvoiceData;
    this.setState({ isInvoiceData: !this.state.isInvoiceData });
    let { campaignOverview } = this.props;
    if (campaignOverview && campaignOverview.is_two_customer && this.state.selectedTranCustomerType.value != "All"
      && (this.state.selectedGroup.value !== "All" || this.state.selectedBrand.value != "All") && this.state.selectedPublisher.value == "ATR") {
      filterSelect.tranCustomerType = this.state.selectedTranCustomerType.value;
    } else {
      delete filterSelect.tranCustomerType;
    }
    this.props.handleFilterChange(filterSelect);
  }

  selectOnCustomerTypeChange = async (e) => {
    this.setState({ selectedTranCustomerType: e });
    console.log("selectOnCustomerTypeChange", e);
    let filterSelect = this.state.allSelectedFilter;
    if (e.value != "All") {
      filterSelect.tranCustomerType = e.value;
    } else {
      delete filterSelect.tranCustomerType;
    }
    filterSelect.is_invoice_data = this.state.isInvoiceData;
    this.props.handleFilterChange(filterSelect);
    // this.getUploadedDate("tranCustomerType", e.value);
  }

  selectOnOfferTypeChange = async (e) => {
    this.setState({ selectedOfferType: e });
    console.log("selectedOfferType", e);
    let filterSelect = this.state.allSelectedFilter;
    if (e.value != "All") {
      filterSelect.selectedOfferType = e.value;
    } else {
      delete filterSelect.selectedOfferType;
    }
    filterSelect.is_invoice_data = this.state.isInvoiceData;
    this.props.handleFilterChange(filterSelect);
  }


  selectOnInvoiceNoChange = async (e) => {
    this.setState({ selectedInvoiceNumber: e });
    let filterSelect = this.state.allSelectedFilter;
    filterSelect.is_invoice_data = this.state.isInvoiceData;
    filterSelect.selectedInvoiceNumber = e;
    this.props.handleFilterChange(filterSelect);
  }

  getUploadedDate = async (changeFrom, value) => {
    let getIngObj = {};
    if ((this.state.selectedPublisher && this.state.selectedPublisher.value && this.state.selectedPublisher.value != "All") ||
      (changeFrom == "publisher" && value != "All")) {
      getIngObj.data_source = changeFrom == "publisher" ? value : this.state.selectedPublisher.value;
    }
    if ((this.state.selectedGroup && this.state.selectedGroup.value && this.state.selectedGroup.value != "All") ||
      (changeFrom == "group" && value != "All")) {
      getIngObj.groupId = changeFrom == "group" ? value : this.state.selectedGroup.value;
    }
    if (changeFrom != "group" && (this.state.selectedBrand && this.state.selectedBrand.value && this.state.selectedBrand.value != "All") ||
      (changeFrom == "brand" && value != "All")) {
      getIngObj.brandId = changeFrom == "brand" ? value : this.state.selectedBrand.value;
      delete getIngObj.groupId;
    }

    // if((this.state.defaultValue && this.state.defaultValue.value) ||
    // (changeFrom == "campaign" && value != "All")) {
    //     getIngObj.campaign_id = changeFrom == "campaign" ? this.state.defaultValue.value : value;
    // }

    axiosMethodRequest('POST', 'ingestionLogs/getUploadedDate', getIngObj).then((response) => {
      if (response && response.data && response.data.respCode) {
        let formatDate = {
          uploadedDate: response.data.ingestionLogDate.uploadedDate,
          next_ingestion_date: response.data.ingestionLogDate.next_ingestion_date,
          last_trans_date: response.data.ingestionLogDate.last_trans_date
        }
        this.setState({ ingestionLogDate: formatDate, ingestionLogList: response.data.ingestionLogList });
      }
    });
  }

  componentWillUnmount() {
    let { clearCampaignOverview } = this.props;
    // console.log('components will unmount called')
    clearCampaignOverview()
  }
  applyFilter = () => {
    let filter = {}
    let { handleFilterChange } = this.props;
    if (this.state.start_date) {
      filter['start_date'] = moment(this.state.start_date).toISOString()
    }
    if (this.state.end_date) {
      filter['end_date'] = moment(this.state.end_date).toISOString()
    }
    if (!Object.keys(filter).length)
      return;
    handleFilterChange(filter)
    this.setState({ showFilter: !this.state.showFilter })
  }
  clearDate = props => {
    props.onChange({ target: { value: '' } })
  }
  onChange = value => {
    let x = this.props
  }

  downloadInvoice = () => {
    this.setState({ isloader: true })
    let loginCredentials = GetSessionValue('loginCredentials')
    if (loginCredentials) {
      let items = this.state.campaigns.filter(res => res.data && res.data.campaign_id == this.state.selectValue.value)
      if (items.length > 0) {
        let date = new Date(items[0].data.openingTime)
        this.props.getInvoiceDataAction(loginCredentials.brandName, date.getMonth() + 1, date.getFullYear())
      }
    }
  }

  ViewTransaction = () => {
    let loginCredentials = GetSessionValue('loginCredentials')
    if (loginCredentials) {
      this.setState({ isShowTransModel: true })
      this.props.getInvoiceDataAction(loginCredentials.brandName, "", "", "transaction")
    }
  }

  showHideInvoiceButton = (data) => {
    // console.log(data)
    let items = data.filter(res => res.data && res.data.campaign_id == this.state.selectValue.value)
    if (items.length > 0) {
      var date = new Date();
      var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      console.log(firstDay)
      if (new Date(items[0].data.openingTime) < firstDay) {
        return "Download Invoice"
      } else {
        return "View Transaction"
      }
      // items[0].data.openingTime
    }
    // console.log(this.state.selectValue.value)
  }

  exportTransaction = async () => {
    let conditions = {};

    if (this.state.selectedPublisher && this.state.selectedPublisher.value !== "All") {
      conditions.data_source = this.state.selectedPublisher.value;
    }

    if (this.state.selectedGroup && this.state.selectedGroup.value && this.state.selectedGroup.value !== "All") {
      conditions.group_id = this.state.selectedGroup.value;
    }

    if (this.state.selectedBrand && this.state.selectedBrand.value && this.state.selectedBrand.value !== "All") {
      conditions.brand_id = this.state.selectedBrand.value;
      conditions.brand_name = this.state.selectedBrand.label;
    }

    if (this.state.defaultValue && this.state.defaultValue.value) {
      conditions.campaign_id = this.state.defaultValue.value;
    }

    if (Object.keys(conditions).length === 0) {
      if (this.state.userDetails.role === "groupAdmin" || this.state.userDetails.role === 'groupUser') {
        conditions.group_id = this.state.userDetails.brandId;
      } else if (this.state.userDetails.role === "brandAdmin" || this.state.userDetails.role === 'brandUser') {
        conditions.brand_id = this.state.userDetails.brandId;
      }
    }

    // conditions.is_invoice_data = this.state.isInvoiceData;

    // if (this.state.isInvoiceData && this.state.selectedInvoiceNumber) {
    //   conditions.selectedInvoiceNumber = this.state.selectedInvoiceNumber;
    // }

    if (this.state.selectedOfferType && this.state.selectedOfferType.value && this.state.selectedOfferType.value !== "All") {
      conditions.selectedOfferType = this.state.selectedOfferType.value;
    }
    let email = this.state.userDetails.email
    let request = {
      conditions_body: { conditions },
      field: []
    };
    this.setState({ isloader: true })

    console.log("Request for teh Export    :   " + JSON.stringify(request))
    console.log("Request for teh Export    :   " + JSON.stringify(email))


    // this.exportTransactionApiCall(request,email, (response) => {
    //   this.setState({ isloader: false });
    //   let { successNotification, errorNotification } = this.props;
    //   if (response&& response.data.responseCode) {
    //     let notification = { ...notificationOpts };
    //     notification.message = "File exported successfully. You will receive an email with a link to download the file.";
    //     successNotification(notification);
    //   } else if (response && response.data.responseCode) {
    //     let notification = { ...notificationOpts };
    //     notification.message = response.data;
    //     errorNotification(notification);
    //   }
    // });

    this.exportTransactionApiCall(request, email, (response) => {
      let { successNotification, errorNotification } = this.props;
      this.setState({ isloader: false });
      if (response && response.responseCode === "200") {
        let notification = { ...notificationOpts };
        notification.message = "File exported successfully. You will receive an email with a link to download the file.";
        successNotification(notification);
      } else if (response && response.responseCode === "400") {
        console.log("exportTransactionApiCall   3 :     " + JSON.stringify(response))
        let notification = { ...notificationOpts };
        notification.message = response.data;
        errorNotification(notification);
      }
    });
  }

  // ToastMessage = (message, type = undefined) => {
  //   console.log("Toast Message   :  " + message)
  //   let toastOptions = {
  //     text: message,
  //     duration: 1500,
  //     close: false,
  //     gravity: "top", // `top` or `bottom`
  //     position: "right", // `left`, `center` or `right`
  //     style: {
  //       background: type ? "#98C33C" : "#dc3545"
  //     },
  //     stopOnFocus: true, // Prevents dismissing of toast on hover
  //     onClick: function () { }, // Callback after click
  //   }//.showToast();
  //   StartToastifyInstance(toastOptions)
  // };



  exportTransactionApiCall = (data, email, callBack) => {
    const url = '/export'
    // return dispatch => {
    // const url = `transaction/exportTransaction${data}`
    return getDetailedInfo(url, data, email).then((response) => {
      callBack(response);
    })
    // }
  }

  viewInvoice = async () => {
    let { getXeroInvoice, errorNotification, campaignOverview } = this.props;
    let data = {
      xero_invoiceNumber: this.state.selectedInvoiceNumber
    }

    if (!this.state.selectedInvoiceNumber && campaignOverview && campaignOverview.invoiceList && campaignOverview.invoiceList.length == 1) {
      data.xero_invoiceNumber = campaignOverview.invoiceList[0].xero_invoiceNumber;
    }
    getXeroInvoice(data, async (res) => {
      if (res && res.invoiceURL) {
        window.open(res.invoiceURL, '_blank');
      } else if (res && res.respMessage) {
        setTimeout(() => {
          let notification = { ...notificationOpts };
          notification.message = res.respMessage;
          errorNotification(notification);
        })
      } else {
        let notification = { ...notificationOpts };
        notification.message = "Invoice is not created.";
        errorNotification(notification);
      }
    })
  }

  render() {
    let { campaignOverview, handleFilterChange, loadingState, title, displayFilter, overviewType = 'aggregate', hideSearch, campaign } = this.props;
    let thisMonth = moment().startOf('month');

    return (
      <>
        {this.state.isShowTransModel
          &&
          <TransactionList close={() => {
            this.setState({ isShowTransModel: false })
          }} />
        }
        {this.state.isloader && <div className="loading">Loading...</div>}
        {loadingState && <div className="loading">Loading...</div>}
        <div>
          {/* {this.state.dashboardRoute ? <Redirect to={`/user/dashboard?campaignId=${this.state.dashboardRoute}`} /> : <Redirect to={`/user/dashboard`} />} */}
          <div className="heading">
            <div className="row">
              <div className="col-12 d-flex align-items-center">
                <div className="col-md-5">
                  <h2 className="m-0">
                    <span>
                      <Icon className="heading-icon">adjust</Icon>
                    </span> {title}
                    {/* {
                      displayFilter
                        ? (
                          <button className="btn btn pull-right btn-round-navy ml-2" onClick={() => this.setState({ showFilter: !this.state.showFilter })}>
                            <i className="fa fa-filter"></i>
                          </button>
                        ) : ''
                    } */}
                  </h2>
                </div>
                {/* <div className="col-md-7 text-right invoice-data-toogle">
                  {((this.state.selectedGroup && this.state.selectedGroup.value !== "All") ||
                    (this.state.selectedBrand && this.state.selectedBrand.value !== "All")) &&
                    this.state.defaultValue && this.state.defaultValue.value !== null &&
                    <>
                      <div className='title'>All Data </div>
                      <div className="toggle-button-cover">
                        <div className="button-cover">
                          <div className="button r" id="button-1">
                            <input type="checkbox" className="checkbox"
                              onChange={(e) => {
                                this.onIsInvoiceChangeData();
                              }}
                            />
                            <div className="knobs"></div>
                            <div className="layer"></div>
                          </div>
                        </div>
                      </div>
                      <div className='title'>Invoiced Data </div>

                      {this.state.isInvoiceData && campaignOverview.invoiceList && campaignOverview.invoiceList.length > 0 &&
                        <>
                          <select
                            name="invoice_number"
                            className="form-control dropdown inv-no-dropdown"
                            value={this.state.selectedInvoiceNumber}
                            onChange={(e) => {
                              this.selectOnInvoiceNoChange(e.target.value);
                            }}
                          >
                            {campaignOverview.invoiceList.map(res1 => {
                              return (
                                <option value={res1.xero_invoiceNumber}>{res1.xero_invoiceNumber}</option>
                              )
                            })}
                          </select>

                          {((this.state.selectedInvoiceNumber && this.state.selectedInvoiceNumber != "All") || (campaignOverview && campaignOverview.invoiceList && campaignOverview.invoiceList.length == 1)) &&
                            <div className='title m-l-10'>
                              <Icon style={{ fontSize: "2rem" }} className="text-info link-pointer-icon" data-toggle="tool-tip"
                                onClick={() => { this.viewInvoice() }} title="View Invoice">visibility</Icon>
                            </div>
                          }
                        </>
                      }

                    </>
                  }
                </div> */}
              </div>
              {/* {(this.state.selectValue.isAll || this.state.campaigns.length === 1) && <div className="col-md-2"></div>} */}


              <div className="row col-12 d-flex  m-t-10">
                {/* ....................Group Selection.................... */}
                {
                  this.state.userDetails.isClientAdmin ?
                    <div className="col-md-3">
                      <div className="filter-title"> Group </div>
                      <div className="search">
                        <Select
                          cacheOptions
                          options={this.state.groupList}
                          value={this.state.selectedGroup}
                          classNamePrefix="reactselect"
                          onChange={(e) => this.selectOnGroupChange(e)}
                        />
                      </div>
                      {/* <div>clear</div> */}
                    </div>

                    : <></>
                  // <div className="col-md-3"></div>
                }

                {/* ....................Brand Selection.................... */}
                {
                  this.state.userDetails.isClientAdmin || this.state.userDetails.role === "groupAdmin" || this.state.userDetails.role === "groupUser" ? (
                    <div className="col-md-3">
                      <div className="filter-title"> Brand </div>
                      <div className="search">
                        <Select
                          cacheOptions
                          options={this.state.brandsList}
                          value={this.state.selectedBrand}
                          classNamePrefix="reactselect"
                          onChange={(e) => this.selectOnBrandChange(e)}
                        />
                      </div>

                    </div>
                  ) :
                    // <div className="col-md-3"></div>
                    <></>
                }

                {/* ....................Campaign Selection..................... */}
                {
                  !hideSearch && campaignOverview && Object.keys(campaignOverview).length > 0 ? (
                    <div className="col-md-3">
                      <div className="filter-title"> Campaign </div>
                      <div className="search">
                        <Select
                          cacheOptions
                          // options={
                          //   (value, callback) => {
                          //     let { getCampaignOverviewSearchOptions } = this.props;
                          //     getCampaignOverviewSearchOptions({ search: value }, callback)
                          //   }
                          // }
                          options={this.state.campaigns}
                          // onInputChange={(value, e) => {
                          //   if (e.action != "menu-close")
                          //     this.getBrand(value)
                          // }}
                          value={this.state.defaultValue}
                          // defaultOptions
                          // getOptionLabel={option => option.campaign_id}
                          // getOptionValue={option => option.id}
                          classNamePrefix="reactselect"
                          // onChange={handleFilterChange}
                          onChange={(e) => this.selectOnChange(e)}
                        />
                      </div>
                    </div>
                  ) : ''
                }

                {/* ....................Publisher Selection..................... */}

                {campaignOverview && Object.keys(campaignOverview).length > 0 &&
                  <div className="col-md-3">
                    <div className="filter-title"> Publisher </div>
                    <div className="search">
                      <Select
                        cacheOptions
                        options={this.state.publisherList}
                        value={this.state.selectedPublisher}
                        classNamePrefix="reactselect"
                        onChange={(e) => this.selectOnPublisherChange(e)}
                      />
                    </div>
                    {/* <div>clear</div> */}
                  </div>
                }

                {/* ....................Customer Type Selection..................... */}

                {campaignOverview && campaignOverview.is_two_customer && (this.state.selectedGroup.value !== "All" || this.state.selectedBrand.value != "All") && this.state.selectedPublisher.value == "ATR" &&
                  <div className="col-md-3">
                    <div className="filter-title"> Customer Type </div>
                    <div className="search">
                      <Select
                        cacheOptions
                        options={this.state.tranCustomerTypes}
                        value={this.state.selectedTranCustomerType}
                        classNamePrefix="reactselect"
                        onChange={(e) => this.selectOnCustomerTypeChange(e)}
                      />
                    </div>
                  </div>
                }

                {/* ....................Offer Type Selection..................... */}

                {campaignOverview && campaignOverview.offerTypeList && campaignOverview.offerTypeList.length > 0 &&
                  <div className="col-md-3">
                    <div className="filter-title"> Offer Type </div>
                    <div className="search">
                      <Select
                        cacheOptions
                        options={campaignOverview.offerTypeList}
                        value={this.state.selectedOfferType}
                        classNamePrefix="reactselect"
                        onChange={(e) => this.selectOnOfferTypeChange(e)}
                      />
                    </div>
                  </div>
                }

                {/* {this.state.campaigns.length > 1 && !this.state.selectValue.isAll && this.showHideInvoiceButton(this.state.campaigns) == "Download Invoice" &&
                    <div className="col-md-2 text-center">
                      <button onClick={() => this.downloadInvoice()} className="btn btn-primary"> Download Invoice <i className="fa fa-download"></i></button>
                    </div>
                  }
                  {this.state.campaigns.length > 1 && !this.state.selectValue.isAll && this.showHideInvoiceButton(this.state.campaigns) == "View Transaction" &&
                    <div className="col-md-2 text-center">
                      <button onClick={() => this.ViewTransaction()} className="btn btn-primary"> View Transaction
                      </button>
                    </div>
                  } */}
              </div>
              {/* <div className="d-flex row col-1 override-m-p p-t-7">
                        <div className="col-12 d-flex dashboard-filter-box w-100">
                            <div className="form-group w-100">
                                <button className={`btn btn-sm nav-link tab-selector w-100 active btn-primary m-t-20`}
                                    onClick={(e) => search()}>
                                    Search
                                </button>
                            </div>
                        </div>
                    </div> */}
              {campaignOverview && Object.keys(campaignOverview).length > 0 &&
                <div className="row col-12 d-flex justify-content-end m-t-10">
                  <div className="col-md-1">
                    <button className={`btn btn-sm nav-link tab-selector w-100 active btn-primary m-t-20`} onClick={() => { this.exportTransaction() }}>
                      Export
                    </button>
                  </div>
                </div>
              }

            </div>
            <div className="row">
              <div className="col-md-12">
                {
                  displayFilter
                    ? (
                      <Collapse
                        in={this.state.showFilter}
                      >
                        <div className="card font-size-normal">
                          <div className="form-group mb-3">
                            <label>Date Range </label>
                            <br />
                            <div className="row">
                              <div className="col-md-3">
                                <Datetime
                                  viewMode="months"
                                  timeFormat={false}
                                  dateFormat="MM/YYYY"
                                  isValidDate={current => {
                                    if (this.state.end_date)
                                      return current.isSameOrBefore(this.state.end_date, 'month') && current.isBefore(thisMonth, 'month')
                                    return current.isBefore(thisMonth, 'month')
                                  }}
                                  onChange={(date) => this.handleDateChange('start_date', date)}
                                  renderInput={(props, openCalendar) => {
                                    return (
                                      <div className="input-group mb-3">
                                        <input {...props} type="text" placeholder="Start Month" className="form-control" name="start_month" />
                                        <div className="input-group-append date-dropdown-btn">
                                          {this.state.start_date
                                            ? <span className="input-group-text" onClick={this.clearDate.bind(this, props)}><i className="fa fa-times" ></i></span>
                                            : ''
                                          }
                                          <span className="input-group-text" onClick={openCalendar}><i className="fa fa-chevron-down" ></i></span>
                                        </div>
                                      </div>
                                    )
                                  }}
                                />
                              </div>
                              <div className="col-md-3">
                                <Datetime
                                  viewMode="months"
                                  timeFormat={false}
                                  dateFormat="MM/YYYY"
                                  minDate={this.state.end_date}
                                  isValidDate={current => {
                                    if (this.state.start_date)
                                      return current.isSameOrAfter(this.state.start_date, 'month') && current.isBefore(thisMonth, 'month')
                                    return current.isBefore(thisMonth, 'month')
                                  }}
                                  onChange={(date) => this.handleDateChange('end_date', date)}
                                  renderInput={(props, openCalendar) => {
                                    return (
                                      <div className="input-group mb-3">
                                        <input {...props} type="text" placeholder="End Month" className="form-control" name="end_month" />
                                        <div className="input-group-append date-dropdown-btn">
                                          {this.state.end_date
                                            ? <span className="input-group-text" onClick={this.clearDate.bind(this, props)}><i className="fa fa-times" ></i></span>
                                            : ''
                                          }
                                          <span className="input-group-text" onClick={openCalendar}><i className="fa fa-chevron-down" ></i></span>
                                        </div>
                                      </div>
                                    )
                                  }}
                                />
                              </div>
                              <div className="col-md-1">
                                <button className="btn btn btn-navy" onClick={this.applyFilter}>
                                  <i className="fa fa-check"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Collapse>
                    ) : ''
                }
              </div>
            </div>
          </div>
          {
            campaignOverview ? <CampaignOverview campaignOverview={campaignOverview} overviewType={overviewType}
              ingestionLogDate={this.state.ingestionLogDate} ingestionLogList={this.state.ingestionLogList} /> : ''
          }
          <SessionExpiryModal
            isOpen={this.state.sessionExpired}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  invoiceState: state.campaignOverview.invoiceList || {},
  campaign: state.campaignOverview.search || { search: [] },
  loadingState: state.campaignOverview.loadingState || false
});



export default connect(
  mapStateToProps,
  dispatch => ({
    // getCampaignOverviewSearchOptions: bindActionCreators(getCampaignOverviewSearchOptionsAction, dispatch),
    clearCampaignOverview: bindActionCreators(clearCampaignOverviewAction, dispatch),
    // setCampaignOverviewOptions: bindActionCreators(setCampaignOverviewSearchAction, dispatch),
    getInvoiceDataAction: bindActionCreators(getInvoiceDataAction, dispatch),
    clearInvoiceDataAction: bindActionCreators(clearInvoiceDataAction, dispatch),
    generateInvoiceDataAction: bindActionCreators(generateInvoiceDataAction, dispatch),
    getBrandGroupWise: bindActionCreators(getBrandGroupWiseAction, dispatch),
    getGroups: bindActionCreators(getGroupAction, dispatch),
    getDistributors: bindActionCreators(getDistributorsAction, dispatch),
    successNotification: bindActionCreators(successNotificationAction, dispatch),
    errorNotification: bindActionCreators(errorNotificationAction, dispatch),
    getXeroInvoice: bindActionCreators(getXeroInvoiceAction, dispatch),
  })
)(CampaignOverviewLayout)



