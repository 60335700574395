import React, { Component } from "react";
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { logoutAction } from '../../redux/actions/authActions'
import ModalComponent from '../Modal'
import { Collapse } from 'react-bootstrap';
import classNames from 'classnames'
import Icon from '@material-ui/core/Icon'

class Settings extends Component {

  state = {
    showLogoutModal: false,
    showSettingsAlert: false,
  };

  onCloseLogoutModal = () => {
    this.setState({ showLogoutModal: false });
  }

  setShowLogoutModal = async () => {
    await this.props.hideMobileMenu();
    setTimeout(async () => {
      await this.setState({ showLogoutModal: true });
    }, 1000)
  }
  onCloseSettingsAlert = () => {
    this.setState({ showSettingsAlert: false });
  }

  clearStorage = () => {
    this.props.logout();
  }

  setShowSettingsAlert = async () => {
    await this.props.hideMobileMenu();
    setTimeout(async () => {
      await this.setState({ showSettingsAlert: true });
    }, 1000)

  }
  handleLogout = () => {
    this.onCloseLogoutModal()
    this.props.logout()
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }
  componentDidMount() {
    if (this.isPathActive('/user/settings'))
      this.setState({ settingsMenuOpen: true })
  }
  getCardProviderSettings = () => {
    return (
      <ul className="nav settings flex-column">
        <li>
          <button onClick={this.setShowLogoutModal} >
            <i><Icon style={{ fontSize: "20px" }}>lock</Icon></i>
            <p>Logout</p>
          </button>
        </li>
      </ul>
    )
  }
  getBrandSettings = () => {
    let { user, sidebarNavVisibility } = this.props
    return (
      <ul className="nav settings flex-column">
        {/*<li 
          className={
            classNames({
              active : this.isPathActive('/user/settings') || this.state.settingsMenuOpen
            })
          }
        >
          <button onClick={() => this.setState({ settingsMenuOpen: !this.state.settingsMenuOpen })}
            data-toggle="collapse">
            <i><Icon style={{fontSize: "20px"}}>settings</Icon></i>
            <p>
              Settings
            <b 
              className={
                classNames('caret', {
                  open : this.state.settingsMenuOpen
                })
              }></b>
            </p>
          </button>
          <Collapse in={this.state.settingsMenuOpen}>
            <div>
              <ul className="nav flex-column">
                <li 
                  className={
                    classNames({
                      active : this.isPathActive('/user/branches')
                    })
                  }
                >
                  <Link to="/user/branches">
                    <i><Icon style={{fontSize: "20px"}}>domain</Icon></i>
                    <p>Branches</p>
                  </Link>
                </li>
                <li 
                  className={
                    classNames({
                      active : this.isPathActive('/user/brand/users')
                    })
                  }
                >
                  <Link to="/user/brand/users">
                    <i><Icon style={{fontSize: "20px"}}>person_outline</Icon></i>
                    <p>Brand Users</p>
                  </Link>
                </li>
                <li 
                  className={
                    classNames({
                      active : this.isPathActive('/user/settings/brand')
                    })
                  }
                >
                  <Link to="/user/settings/brand">
                    <i><Icon style={{fontSize: "20px"}}>person_outline</Icon></i>
                    <p>My Brand</p>
                  </Link>
                </li>
                <li 
                  className={
                    classNames({
                      active : this.isPathActive('/user/settings/profile')
                    })
                  }
                >
                  <Link to="/user/settings/profile">
                    <i><Icon style={{fontSize: "20px"}}>person_outline</Icon></i>
                    <p>My Profile</p>
                  </Link>
                </li>
              </ul>
            </div>
          </Collapse>
        </li>*/}
        {this.props.user && this.props.user['guestUser'] && this.props.user['guestUserFlag'] ?
          <li
            className={
              classNames({
                active: this.isPathActive('/user/settings') || this.state.settingsMenuOpen
              })
            }
          >
            <button onClick={() => this.setState({ settingsMenuOpen: !this.state.settingsMenuOpen })}
              data-toggle="collapse">
              <i><Icon style={{ fontSize: "20px" }}>settings</Icon></i>
              <p>
                Settings
                <b
                  className={
                    classNames('caret', {
                      open: this.state.settingsMenuOpen
                    })
                  }></b>
              </p>
            </button>
            <Collapse in={this.state.settingsMenuOpen}>
              <div>
                <ul className="nav flex-column">
                  {/* <li 
                  className={
                    classNames({
                      active : this.isPathActive('/user/settings/brand') || this.isPathActive('/user/settings/branches') || this.state.brandInfoMenuOpen
                    })
                  }
                >
                  <button onClick={() => this.setState({ brandInfoMenuOpen: !this.state.brandInfoMenuOpen })}
                    data-toggle="collapse">
                    <i><Icon style={{fontSize: "20px"}}>info</Icon></i>
                    <p>
                      Brand Information
                    <b 
                      className={
                        classNames('caret', {
                          open : this.state.brandInfoMenuOpen
                        })
                      }></b>
                    </p>
                  </button>
                  <Collapse in={this.state.brandInfoMenuOpen}>
                    <div>
                      <ul className="nav flex-column">
                        <li 
                          className={
                            classNames({
                              active : this.isPathActive('/user/settings/brand')
                            })
                          }
                        >
                          <Link to="/user/settings/brand">
                            <i><Icon style={{fontSize: "20px"}}>account_balance</Icon></i>
                            <p>My Brand</p>
                          </Link>
                        </li>
                        <li 
                          className={
                            classNames({
                              active : this.isPathActive('/user/settings/branches')
                            })
                          }
                        >
                          <Link to="/user/settings/branches">
                            <i><Icon style={{fontSize: "20px"}}>domain</Icon></i>
                            <p>Branches</p>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </Collapse>
                </li> */}
                  <li
                    className={
                      classNames({
                        active: this.isPathActive('/user/settings/profile')
                      })
                    }
                  >
                    <div
                      className={!sidebarNavVisibility ? 'navLinksPaddingOnSidbarClose navLinksPadding'
                        : 'navLinksPadding'}
                      onClick={this.setShowSettingsAlert}>
                      <i><Icon style={{ fontSize: "20px" }}>person_outline</Icon></i>
                      <p>My Profile</p>
                    </div>
                  </li>
                  <li
                    className={
                      classNames({
                        active: this.isPathActive('/user/settings/changepassword')
                      })
                    }
                  >
                    <div className={!sidebarNavVisibility ? 'navLinksPaddingOnSidbarClose navLinksPadding'
                      : 'navLinksPadding'}
                      onClick={this.setShowSettingsAlert}>
                      <i><Icon style={{ fontSize: "20px" }}>lock</Icon></i>
                      <p>Change Password</p>
                    </div>
                  </li>
                  {(user.role == 'companyAdmin' || user.role == 'groupAdmin' || user.role == 'brandAdmin') ? <li
                    className={
                      classNames({
                        active: this.isPathActive('/user/branches')
                      })
                    }
                  >
                    <div className={!sidebarNavVisibility ? 'navLinksPaddingOnSidbarClose navLinksPadding'
                      : 'navLinksPadding'}
                      onClick={this.setShowSettingsAlert}>
                      <i><Icon style={{ fontSize: "20px" }}>domain</Icon></i>
                      <p>Branches</p>
                    </div>
                  </li> : null}
                  <li
                    className={
                      classNames({
                        active: this.isPathActive('/user/users')
                      })
                    }
                  >
                    <div className={!sidebarNavVisibility ? 'navLinksPaddingOnSidbarClose navLinksPadding'
                      : 'navLinksPadding'}
                      onClick={this.setShowSettingsAlert}>
                      <i><Icon style={{ fontSize: "20px" }}>person</Icon></i>
                      <p>Users</p>
                    </div>
                  </li>
                  <li
                    className={
                      classNames({
                        active: this.isPathActive('/user/campaigns/payments')
                      })
                    }
                  >
                    <div
                      className={!sidebarNavVisibility ? 'navLinksPaddingOnSidbarClose navLinksPadding'
                        : 'navLinksPadding'}
                      onClick={this.setShowSettingsAlert}>
                      <i><Icon style={{ fontSize: "20px" }}>attach_money</Icon></i>
                      <p>Payments</p>
                    </div>
                  </li>
                  {(user.role === 'companyAdmin' || user.role === 'brandAdmin') && 
                      <li
                        className={
                          classNames({
                            active: this.isPathActive('/user/campaigns/invoices')
                          })
                        }
                      >
                        <div
                          className={!sidebarNavVisibility ? 'navLinksPaddingOnSidbarClose navLinksPadding'
                            : 'navLinksPadding'}>
                          <i><Icon style={{ fontSize: "20px" }}>receipt</Icon></i>
                          <p>Invoices</p>
                        </div>
                      </li>
                  }
                </ul>
              </div>
            </Collapse>
          </li>
          :
          <li
            className={
              classNames({
                active: this.isPathActive('/user/settings') || this.state.settingsMenuOpen
              })
            }
          >
            <button onClick={() => this.setState({ settingsMenuOpen: !this.state.settingsMenuOpen })}
              data-toggle="collapse">
              <i><Icon style={{ fontSize: "20px" }}>settings</Icon></i>
              <p>
                Settings
                <b
                  className={
                    classNames('caret', {
                      open: this.state.settingsMenuOpen
                    })
                  }></b>
              </p>
            </button>
            <Collapse in={this.state.settingsMenuOpen}>
              <div>
                <ul className="nav flex-column">
                  {/* <li 
                  className={
                    classNames({
                      active : this.isPathActive('/user/settings/brand') || this.isPathActive('/user/settings/branches') || this.state.brandInfoMenuOpen
                    })
                  }
                >
                  <button onClick={() => this.setState({ brandInfoMenuOpen: !this.state.brandInfoMenuOpen })}
                    data-toggle="collapse">
                    <i><Icon style={{fontSize: "20px"}}>info</Icon></i>
                    <p>
                      Brand Information
                    <b 
                      className={
                        classNames('caret', {
                          open : this.state.brandInfoMenuOpen
                        })
                      }></b>
                    </p>
                  </button>
                  <Collapse in={this.state.brandInfoMenuOpen}>
                    <div>
                      <ul className="nav flex-column">
                        <li 
                          className={
                            classNames({
                              active : this.isPathActive('/user/settings/brand')
                            })
                          }
                        >
                          <Link to="/user/settings/brand">
                            <i><Icon style={{fontSize: "20px"}}>account_balance</Icon></i>
                            <p>My Brand</p>
                          </Link>
                        </li>
                        <li 
                          className={
                            classNames({
                              active : this.isPathActive('/user/settings/branches')
                            })
                          }
                        >
                          <Link to="/user/settings/branches">
                            <i><Icon style={{fontSize: "20px"}}>domain</Icon></i>
                            <p>Branches</p>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </Collapse>
                </li> */}
                  {/* <li
                    className={
                      classNames({
                        active: this.isPathActive('/user/settings/campaign')
                      })
                    }
                  >
                    <Link to="/user/settings/campaign">
                      <i><Icon style={{ fontSize: "20px" }}>person_outline</Icon></i>
                      <p>Campaign</p>
                    </Link>
                  </li> */}
                  <li
                    className={
                      classNames({
                        active: this.isPathActive('/user/settings/profile')
                      })
                    }
                  >
                    <Link to="/user/settings/profile">
                      <i><Icon style={{ fontSize: "20px" }}>person_outline</Icon></i>
                      <p>My Profile</p>
                    </Link>
                  </li>
                  <li
                    className={
                      classNames({
                        active: this.isPathActive('/user/settings/changepassword')
                      })
                    }
                  >
                    <Link to="/user/settings/changepassword">
                      <i><Icon style={{ fontSize: "20px" }}>lock</Icon></i>
                      <p>Change Password</p>
                    </Link>
                  </li>
                  {(user.role == 'companyAdmin' || user.role == 'groupAdmin' || user.role == 'brandAdmin') ? <li
                    className={
                      classNames({
                        active: this.isPathActive('/user/branches')
                      })
                    }
                  >
                    <Link to="/user/branches">
                      <i><Icon style={{ fontSize: "20px" }}>domain</Icon></i>
                      <p>Branches</p>
                    </Link>
                  </li> : null}
                  <li
                    className={
                      classNames({
                        active: this.isPathActive('/user/users')
                      })
                    }
                  >
                    <Link to="/user/users">
                      <i><Icon style={{ fontSize: "20px" }}>person</Icon></i>
                      <p>Users</p>
                    </Link>
                  </li>
                  <li
                    className={
                      classNames({
                        active: this.isPathActive('/user/campaigns/payments')
                      })
                    }
                  >
                    <Link to="/user/campaigns/payments">
                      <i><Icon style={{ fontSize: "20px" }}>attach_money</Icon></i>
                      <p>Payments</p>
                    </Link>
                  </li>
                  {(user.role === 'companyAdmin' || user.role === 'brandAdmin') && 
                      <li
                        className={
                          classNames({
                            active: this.isPathActive('/user/campaigns/invoices')
                          })
                        }
                      >
                        <Link to="/user/campaigns/invoices">
                          <i><Icon style={{ fontSize: "20px" }}>receipt</Icon></i>
                          <p>Invoices</p>
                        </Link>
                      </li>
                  }
                </ul>
              </div>
            </Collapse>
          </li>
        }
        {this.props.user && this.props.user['guestUser'] && this.props.user['guestUserFlag'] ? 
        <li>
          <button onClick={this.clearStorage} >
            <i><Icon style={{ fontSize: "20px" }}>power</Icon></i>
            <p>Logout</p>
          </button>
        </li>
        : <li>
          <button onClick={this.setShowLogoutModal} >
            <i><Icon style={{ fontSize: "20px" }}>power</Icon></i>
            <p>Logout</p>
          </button>
        </li>}
      </ul>
    )
  }
  getGroupUserSettings = () => {
    let { user, sidebarNavVisibility } = this.props
    return (
      <ul className="nav settings flex-column">
         
        {this.props.user && this.props.user['guestUser'] && this.props.user['guestUserFlag'] ?
          <li
            className={
              classNames({
                active: this.isPathActive('/user/settings') || this.state.settingsMenuOpen
              })
            }
          >
            <button onClick={() => this.setState({ settingsMenuOpen: !this.state.settingsMenuOpen })}
              data-toggle="collapse">
              <i><Icon style={{ fontSize: "20px" }}>settings</Icon></i>
              <p>
                Settings
                <b
                  className={
                    classNames('caret', {
                      open: this.state.settingsMenuOpen
                    })
                  }></b>
              </p>
            </button>
            <Collapse in={this.state.settingsMenuOpen}>
              <div>
                <ul className="nav flex-column">
                  
                  <li
                    className={
                      classNames({
                        active: this.isPathActive('/user/settings/profile')
                      })
                    }
                  >
                    <div
                      className={!sidebarNavVisibility ? 'navLinksPaddingOnSidbarClose navLinksPadding'
                        : 'navLinksPadding'}
                      onClick={this.setShowSettingsAlert}>
                      <i><Icon style={{ fontSize: "20px" }}>person_outline</Icon></i>
                      <p>My Profile</p>
                    </div>
                  </li>
                  <li
                    className={
                      classNames({
                        active: this.isPathActive('/user/settings/changepassword')
                      })
                    }
                  >
                    <div className={!sidebarNavVisibility ? 'navLinksPaddingOnSidbarClose navLinksPadding'
                      : 'navLinksPadding'}
                      onClick={this.setShowSettingsAlert}>
                      <i><Icon style={{ fontSize: "20px" }}>lock</Icon></i>
                      <p>Change Password</p>
                    </div>
                  </li> 
                  { user.role == 'brandUser' && 
                    <li
                      className={
                        classNames({
                          active: this.isPathActive('/user/campaigns/invoices')
                        })
                      }
                    >
                      <div
                        className={!sidebarNavVisibility ? 'navLinksPaddingOnSidbarClose navLinksPadding'
                          : 'navLinksPadding'}>
                        <i><Icon style={{ fontSize: "20px" }}>receipt</Icon></i>
                        <p>Invoices</p>
                      </div>
                    </li>
                  }
                </ul>
              </div>
            </Collapse>
          </li>
          :
          <li
            className={
              classNames({
                active: this.isPathActive('/user/settings') || this.state.settingsMenuOpen
              })
            }
          >
            <button onClick={() => this.setState({ settingsMenuOpen: !this.state.settingsMenuOpen })}
              data-toggle="collapse">
              <i><Icon style={{ fontSize: "20px" }}>settings</Icon></i>
              <p>
                Settings
                <b
                  className={
                    classNames('caret', {
                      open: this.state.settingsMenuOpen
                    })
                  }></b>
              </p>
            </button>
            <Collapse in={this.state.settingsMenuOpen}>
              <div>
                <ul className="nav flex-column">                  
                  <li
                    className={
                      classNames({
                        active: this.isPathActive('/user/settings/profile')
                      })
                    }
                  >
                    <Link to="/user/settings/profile">
                      <i><Icon style={{ fontSize: "20px" }}>person_outline</Icon></i>
                      <p>My Profile</p>
                    </Link>
                  </li>
                  <li
                    className={
                      classNames({
                        active: this.isPathActive('/user/settings/changepassword')
                      })
                    }
                  >
                    <Link to="/user/settings/changepassword">
                      <i><Icon style={{ fontSize: "20px" }}>lock</Icon></i>
                      <p>Change Password</p>
                    </Link>
                  </li> 
                  {user.role == 'brandUser' &&
                    <li
                      className={
                        classNames({
                          active: this.isPathActive('/user/campaigns/invoices')
                        })
                      }
                    >
                      <Link to="/user/campaigns/invoices">
                        <i><Icon style={{ fontSize: "20px" }}>receipt</Icon></i>
                        <p>Invoices</p>
                      </Link>
                    </li>
                  }
                </ul>
              </div>
            </Collapse>
          </li>
        }
        {this.props.user && this.props.user['guestUser'] && this.props.user['guestUserFlag'] ? 
          <li>
          <button onClick={this.clearStorage} >
            <i><Icon style={{ fontSize: "20px" }}>power</Icon></i>
            <p>Logout</p>
          </button>
        </li>
        : <li>
          <button onClick={this.setShowLogoutModal} >
            <i><Icon style={{ fontSize: "20px" }}>power</Icon></i>
            <p>Logout</p>
          </button>
        </li>}
      </ul>
    )
  }
  getBankSettings = () => {
    return (
      <ul className="nav settings flex-column">
        <li
          className={
            classNames({
              active: this.isPathActive('/user/settings') || this.state.settingsMenuOpen
            })
          }
        >
          <button onClick={() => this.setState({ settingsMenuOpen: !this.state.settingsMenuOpen })}
            data-toggle="collapse">
            <i><Icon style={{ fontSize: "20px" }}>settings</Icon></i>
            <p>
              Settings
              <b
                className={
                  classNames('caret', {
                    open: this.state.settingsMenuOpen
                  })
                }></b>
            </p>
          </button>
          <Collapse in={this.state.settingsMenuOpen}>
            <div>
              <ul className="nav flex-column">
                <li
                  className={
                    classNames({
                      active: this.isPathActive('/user/bank/users')
                    })
                  }
                >
                  <Link to="/user/bank/users">
                    <i><Icon style={{ fontSize: "20px" }}>person_outline</Icon></i>
                    <p>Bank Users</p>
                  </Link>
                </li>
                <li
                  className={
                    classNames({
                      active: this.isPathActive('/user/settings/bank')
                    })
                  }
                >
                  <Link to="/user/settings/bank">
                    <i><Icon style={{ fontSize: "20px" }}>domain</Icon></i>
                    <p>My Bank</p>
                  </Link>
                </li>
                <li
                  className={
                    classNames({
                      active: this.isPathActive('/user/settings/profile')
                    })
                  }
                >
                  <Link to="/user/settings/profile">
                    <i><Icon style={{ fontSize: "20px" }}>person_outline</Icon></i>
                    <p>My Profile</p>
                  </Link>
                </li>
              </ul>
            </div>
          </Collapse>
        </li>
        <li>
          <button onClick={this.setShowLogoutModal} >
            <i><Icon style={{ fontSize: "20px" }}>lock</Icon></i>
            <p>Logout</p>
          </button>
        </li>
      </ul>
    )
  }
  getAdminSettings = () => {
    return (
      <ul className="nav settings flex-column">
        <li
          className={
            classNames({
              active: this.isPathActive('/user/settings/profile')
            })
          }
        >
          <Link to="/user/settings/profile">
            <i><Icon style={{ fontSize: "20px" }}>person_outline</Icon></i>
            <p>My Profile</p>
          </Link>
        </li>
        <li>
          <button onClick={this.setShowLogoutModal} >
            <i><Icon style={{ fontSize: "20px" }}>lock</Icon></i>
            <p>Logout</p>
          </button>
        </li>
      </ul>
    )
  }
  getNavs = () => {
    let { user } = this.props;
    if(user.role === 'groupUser' || user.role == 'brandUser')
      return this.getGroupUserSettings()
    else if (user.entityType === 'brand')
      return this.getBrandSettings()
    else if (user.entityType === 'bank')
      return this.getBankSettings()
    else if (user.entityType === 'systemadmin')
      return this.getAdminSettings()
    else if (user.entityType === 'cardprovider')
      return this.getCardProviderSettings()
    else
      return '';
  }
  render() {
    const { showLogoutModal, showSettingsAlert } = this.state
    return (
      <React.Fragment>
        <ModalComponent
          close={this.onCloseLogoutModal}
          show={showLogoutModal}
          callback={this.handleLogout}
          title={"Logout"}
          message={"Are you sure you want to Logout?"}
          action={"Logout"}
        />
        <ModalComponent
          close={this.onCloseSettingsAlert}
          show={showSettingsAlert}
          callback={this.onCloseSettingsAlert}
          title={"Alert"}
          message={"This feature will be enabled once you create your first campaign and validate  your account details"}
          action={"Ok"}
        />
        {
          this.getNavs()
        }
      </React.Fragment>
    );
  }
}

export default withRouter(
  connect(
    null,
    dispatch => ({
      logout: bindActionCreators(logoutAction, dispatch)
    })
  )(Settings)
);

